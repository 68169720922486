const EmmenkoSvg = (props) => (
  <svg
    viewBox="0 0 512 512"
    style={{ zoom: 1 }}
    width="1em"
    height="1em"
    {...props}
  >
    <g className="hexatar-base">
      <path className="col-obj" fill="#e06961" d="M-.003.002h512v512h-512z" />
      <path
        className="shadow-obj"
        opacity={0.8}
        fill="#d84136"
        d="M255.946.002h257.263v512H255.946z"
      />
    </g>
    <path
      className="col-obj"
      fill="#f7cfb3"
      d="M380.515 465L301 451V334h-90v117l-79.364 14-37.521 47h323.92z"
    />
    <path
      className="shadow-obj"
      fill="#f2b285"
      d="M380.541 465L301 451V334h-45v178h162.062z"
    />
    <path
      className="shadow-obj-2x"
      opacity={0.5}
      fill="#ed9557"
      d="M301.054 387.482l-45.056 40.211-45.056-43.078v-51.13h90.112z"
    />
    <g className="hexatar-base">
      <path
        className="shadow-obj-2x"
        d="M210.924 459.744v-36.999l-28.184 4.95 28.184 32.049zm90.13-36.999v36.999l28.244-32.049-28.244-4.95z"
      />
      <path
        d="M382.805 464.994l-35.398-12.023a26.694 26.694 0 0 1-18.109-25.276l-73.259 32.319-73.3-32.319v.001a26.693 26.693 0 0 1-18.107 25.275l-35.389 12.022-37.084 47h327.68l-37.034-46.999z"
        className="col-obj"
        fill="#302b26"
      />
      <path
        className="shadow-obj"
        d="M92.159 511.994h45.42l-8.336-47-37.084 47zm290.646-47l-8.285 47h45.318l-37.033-47z"
        opacity={0.4}
        fill="#14110f"
      />
    </g>
    <g>
      <g className="hexatar-base">
        <g className="hexatar-ears-right">
          <path
            className="col-obj"
            fill="#f7cfb3"
            d="M172.639 176.555v66.592l-18.169 8.27-15.727-8.648-23.147-54.772 26.006-32.261z"
          />
          <path
            className="shadow-obj"
            fill="#f2b285"
            d="M143.639 169.144l-16.503 20.471 19.466 46.063 8.291 4.559 17.746-8.381v-43.838z"
          />
        </g>
        <g className="hexatar-ears-left">
          <path
            className="shadow-obj"
            fill="#f2b285"
            d="M339.621 243.147v-66.592l30.218-20.819 26.006 32.261-22.293 52.752-16.581 10.668z"
          />
          <path
            className="shadow-obj-2x"
            fill="#ed9557"
            d="M367.874 169.233l-28.253 19.15v44.559l16.625 7.05 9.297-5.981 18.762-44.396z"
          />
        </g>
      </g>
      <g className="hexatar-base">
        <path
          className="col-obj"
          fill="#f7cfb3"
          d="M256.005 7.17l-102.4 64.185v147.331l9.287 29.516 18.453 93.448 61.142 44.4h27.855l60.322-44.687 18.453-93.161 9.287-29.516V71.355z"
        />
        <path
          className="shadow-obj"
          d="M256.005 7.17l-.001 378.88h14.338l60.322-44.687 18.454-93.161 9.287-29.516V71.355L256.005 7.17z"
          fill="#f2b285"
        />
      </g>
      <g className="hexatar-base">
        <path
          className="col-obj"
          d="M225.536 314.077h61.606l-7.554 3.65c-6.061 2.929-14.463 4.586-23.249 4.586-8.785 0-17.187-1.657-23.249-4.586l-7.554-3.65z"
          fill="#eca38f"
        />
        <path
          className="shadow-obj"
          d="M272.549 304.077h-10.691l-5.52 8.466-5.52-8.466h-10.691a19.335 19.335 0 0 0-16.932 10h66.285a19.33 19.33 0 0 0-16.931-10z"
          fill="#e57f63"
        />
      </g>
      <g className="hexatar-base">
        <path
          className="col-obj"
          fill="#424242"
          d="M339.689 227.765v73.6l-55.127 57.466H268.29v-7.48l-6.827-16.268h-10.737l-6.827 16.268v7.48h-14.292l-57.07-55.342v-75.724h-18.924v103.917l72.889 70.344h61.165l70.937-70.344-.001 20 .01-123.917z"
        />
        <path
          className="shadow-obj"
          opacity={0.4}
          fill="#282828"
          d="M339.689 227.765v73.6l-55.127 57.466H268.29v-7.48l-6.827-16.268h-5.37v66.943h31.574l70.937-70.344-.001 20 .01-123.917z"
        />
      </g>
      <g className="hexatar-base">
        <path
          className="col-obj"
          d="M304.942 290.058c-3.386-2.758-36.139-23.78-40.034-23.78-3.895 0-9.029 6.086-9.029 6.086s-5.134-6.086-9.03-6.086-36.649 21.023-40.034 23.78c-3.386 2.758-.858 25.932-3.845 30.315 9.651-2.28 14.971-18.812 15.413-22.955.443-4.143 20.103-10.577 25.554-12.416 5.451-1.838 7.861 0 11.941 0 4.081 0 6.49-1.838 11.941 0 5.451 1.838 25.112 8.273 25.554 12.416s5.762 20.675 15.413 22.955c-2.986-4.384-.459-27.558-3.844-30.315z"
          fill="#424242"
        />
        <path
          className="shadow-obj"
          d="M255.878 272.364s5.134-6.086 9.03-6.086c3.895 0 36.649 21.023 40.034 23.78 3.386 2.758.858 25.932 3.845 30.315-9.651-2.28-14.971-18.812-15.413-22.955-.443-4.143-20.103-10.577-25.554-12.416-5.451-1.838-7.861 0-11.941 0v-12.638z"
          opacity={0.4}
          fill="#282828"
        />
      </g>
      <g className="hexatar-base">
        <path
          className="shadow-obj"
          fill="#f2b285"
          d="M271.547 175.435h-31.092l3.803 67.425-9.589 5.755-5.102 13.301 5.286.849 2.53-3.176 13.332 7.692h10.465l13.118-7.692 2.424 3.176 5.179-.849-4.568-13.301-9.589-5.755z"
        />
        <path
          className="shadow-obj-2x"
          fill="#ed9557"
          d="M271.547 175.435h-15.546v91.846h5.179l13.118-7.692 2.424 3.176 5.179-.849-4.568-13.301-9.589-5.755z"
        />
      </g>
      <g className="hexatar-base">
        <g className="hexatar-eye-right">
          <path
            className="white-obj"
            d="M230.638 199.264s.848-6.187-2.68-10.048c-16.9-3.777-36.643 1.638-36.643 1.638l-2.245 5.356s7.557 5.14 21.172 5.14 17.649-.703 17.649-.703 1.06 1.681 2.472.942c1.41-.738.275-2.325.275-2.325z"
            fill="#fff"
          />
          <path
            className="shadow-obj"
            d="M221.277 190.522a10.23 10.23 0 0 0-.8-2.218 54.792 54.792 0 0 0-4.3-.409c-3.53-.069-6.96.11-10.089.413a43.121 43.121 0 0 0-4.26.849 10.149 10.149 0 0 0-.417 5.951 10.145 10.145 0 0 0 4.057 6.024 51.93 51.93 0 0 0 4.772.218c2.59 0 4.828-.026 6.763-.067 3.42-2.286 5.254-6.515 4.274-10.761z"
            opacity={0.3}
          />
          <circle
            className="col-obj"
            cx={211.345}
            cy={192.815}
            r={5.276}
            fill="#111"
          />
          <circle
            className="white-obj"
            cx={208.047}
            cy={196.46}
            r={2.593}
            fill="#fff"
          />
          <path
            className="hair-col"
            d="M219.621 182.83c3.845 1.376 6.318 3.462 7.913 5.633-13.26-4.004-30.059.044-34.766 1.34 3.692-4.455 8.366-7.173 8.366-7.173s-11.234 2.559-12.064 13.579c.554-1.69 2.263-3.193 3.253-4.626 17.455-5.45 31.344-2.767 36.212-1.559 2.122 3.847 2.103 7.494 2.103 7.494s2.369-12.889-11.017-14.688z"
            fill="#424242"
          />
        </g>
        <g className="hexatar-eye-left">
          <path
            className="white-obj"
            d="M286.032 198.786s-.848-6.187 2.68-10.048c16.9-3.777 36.643 1.638 36.643 1.638l2.245 5.356s-7.557 5.14-21.172 5.14-17.649-.703-17.649-.703-1.06 1.681-2.472.942c-1.411-.738-.275-2.325-.275-2.325z"
            fill="#fff"
          />
          <path
            className="shadow-obj"
            d="M295.392 190.044a10.23 10.23 0 0 1 .8-2.218 54.792 54.792 0 0 1 4.3-.409c3.53-.069 6.96.11 10.089.413 1.528.229 2.956.516 4.26.849.707 1.834.892 3.891.417 5.951a10.145 10.145 0 0 1-4.057 6.024 51.93 51.93 0 0 1-4.772.218c-2.59 0-4.828-.026-6.763-.067-3.42-2.286-5.254-6.515-4.274-10.761z"
            opacity={0.3}
          />
          <circle
            className="col-obj"
            cx={305.325}
            cy={192.337}
            r={5.276}
            fill="#111"
          />
          <circle
            className="white-obj"
            cx={301.175}
            cy={195.982}
            r={2.593}
            fill="#fff"
          />
          <path
            className="hair-col-2x"
            d="M297.048 182.352c-3.845 1.376-6.318 3.462-7.913 5.633 13.26-4.004 30.059.044 34.766 1.34-3.692-4.455-8.366-7.173-8.366-7.173s11.234 2.559 12.064 13.579c-.554-1.69-2.184-3.07-3.173-4.503-17.455-5.45-31.424-2.891-36.291-1.682-2.122 3.847-2.103 7.494-2.103 7.494s-1.693-13.892 11.016-14.688z"
            fill="#282828"
          />
        </g>
      </g>
      <g className="hexatar-base">
        <g className="hexatar-eyebrows-right">
          <path
            className="col-obj"
            d="M191.416 162.211l-13.261 14.976 11.872-5.504 41.908 3.466 1.893-9.194-42.412-3.744zm44.267 5.607l-2.537 7.581 2.751.159 4.422-7.031-4.636-.709z"
            fill="#424242"
          />
        </g>
        <g className="hexatar-eyebrows-left">
          <path
            className="col-obj"
            d="M272.368 168.527l4.422 7.031 2.751-.159-2.537-7.581-4.636.709zm48.979-6.316l-42.412 3.744 1.893 9.194 41.908-3.466 11.872 5.504-13.261-14.976z"
            fill="#424242"
          />
          <path
            className="shadow-obj"
            d="M272.368 168.527l4.422 7.031 2.751-.159-2.537-7.581-4.636.709zm48.979-6.316l-42.412 3.744 1.893 9.194 41.908-3.466 11.872 5.504-13.261-14.976z"
            opacity={0.4}
            fill="#282828"
          />
        </g>
      </g>
      <g className="element-front">
        <path
          className="col-obj"
          fill="#424242"
          d="M256.208 6.901L154 71.356V175h19v-75h138.449L339 72.492V175h19V71.356z"
        />
        <path
          className="shadow-obj"
          opacity={0.4}
          fill="#282828"
          d="M256 6.901V100h55.449L339 72.492V175h19V71.356z"
        />
      </g>
      <g>
        <g className="hexatar-base">
          <g className="hexatar-eyeglasses-right">
            <path
              className="col-obj"
              d="M237.418 195.635c-1.524-14.999-14.191-26.703-29.592-26.703-16.428 0-29.746 13.318-29.746 29.746s13.318 29.746 29.746 29.746 29.746-13.318 29.746-29.746c0-.334-.014-.664-.025-.995 1.928-.566 12.571-1.717 18.459-1.717v-2c-5.783 0-16.341 1.036-18.588 1.669zm-5.273 6.688c0 1.836.664 3.429 1.648 4.318-3.404 11.112-13.741 19.193-25.967 19.193-14.998 0-27.156-12.158-27.156-27.156s12.158-27.156 27.156-27.156c14.551 0 26.428 11.445 27.123 25.825-1.594.427-2.804 2.487-2.804 4.976z"
              fill="#7f4117"
            />
          </g>
          <g className="hexatar-eyeglasses-left">
            <path
              className="col-obj"
              d="M255.999 193.966v2c5.888 0 16.531 1.151 18.459 1.717-.011.331-.025.661-.025.995 0 16.428 13.318 29.746 29.746 29.746s29.746-13.318 29.746-29.746-13.318-29.746-29.746-29.746c-15.401 0-28.068 11.704-29.592 26.703-2.248-.633-12.806-1.669-18.588-1.669zm21.057 3.38c.695-14.379 12.571-25.825 27.123-25.825 14.998 0 27.156 12.158 27.156 27.156s-12.158 27.156-27.156 27.156c-12.227 0-22.563-8.081-25.967-19.193.984-.889 1.648-2.482 1.648-4.318-.001-2.488-1.211-4.548-2.804-4.976z"
              fill="#7f4117"
            />
            <path
              className="shadow-obj"
              d="M255.999 193.966v2c5.888 0 16.531 1.151 18.459 1.717-.011.331-.025.661-.025.995 0 16.428 13.318 29.746 29.746 29.746s29.746-13.318 29.746-29.746-13.318-29.746-29.746-29.746c-15.401 0-28.068 11.704-29.592 26.703-2.248-.633-12.806-1.669-18.588-1.669zm21.057 3.38c.695-14.379 12.571-25.825 27.123-25.825 14.998 0 27.156 12.158 27.156 27.156s-12.158 27.156-27.156 27.156c-12.227 0-22.563-8.081-25.967-19.193.984-.889 1.648-2.482 1.648-4.318-.001-2.488-1.211-4.548-2.804-4.976z"
              fill="#542b0f"
            />
          </g>
        </g>
      </g>
    </g>
    <text transform="translate(0 528)" fontSize={12}>
      Avatar generated from Hexatar.com
    </text>
  </svg>
);
EmmenkoSvg.displayName = 'EmmenkoSvg';

export default EmmenkoSvg;
